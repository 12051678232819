<template>
	<v-menu
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		min-width="auto"
		:nudge-left="nudgeLeft"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-on="on"
				v-bind="attrs"
				v-model="computedDateFormatted"
				placeholder="дд.мм.гггг"
				dense
				readonly
				:class="{ 'in-table border-all': inTable, 'width-small': smallWidth }"
				:solo="inTable"
				:flat="inTable"
				:hide-details="inTable"
			>
				<template v-slot:append>
					<v-icon v-on="on" v-bind="attrs">mdi-calendar</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-date-picker
			:range="range"
			:allowed-dates="disablePastDates"
			:value="date"
			:title-date-format="titleDate"
			@input="handleInput"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: "DatePickerPlain",
	props: {
		allowPastDates: {
			default: true,
			type: Boolean,
		},
		date: {
			type: [String, Array],
			// default: new Date().toISOString().substr(0, 10),
			required: true,
		},
		range: {
			type: Boolean,
			default: false,
		},
		nudgeLeft: {
			type: String,
			default: "0",
		},
		smallWidth: {
			type: String,
		},
		inTable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		computedDateFormatted() {
			if (this.range) {
				return Array.isArray(this.date) ? this.date.join(" - ") : "";
			}
			return this.formatDate(this.date);
		},
	},
	methods: {
		titleDate(dates) {
			if (Array.isArray(dates)) {
				return dates
					.map(n => new Date(n).toLocaleString('ru-RU', {
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					}))
					.join(' - ');
			} else if (dates instanceof Date) {
				return new Date(dates).toLocaleString('ru-RU', {
					weekday: 'short',
					month: 'short',
					day: 'numeric',
				});
			} else {
				return dates;
			}
		},
		// * Запрет на выбор прошедших дат
		disablePastDates(val) {
			if (this.allowPastDates) return true;

			return val >= new Date().toISOString().substr(0, 10);
		},
		// * При изменении значения date
		handleInput(e) {
			console.log('e', e)
			if (this.range) {
				if (Array.isArray(e)) {
					const [firstDate, secondDate] = e;
					if (firstDate && secondDate) {
						const date1 = new Date(firstDate);
						const date2 = new Date(secondDate);
						
						if (date1 > date2) {
							// Если первая дата больше второй, меняем их местами
							this.$emit("update:date", [secondDate, firstDate]);
						} else {
							this.$emit("update:date", e);
						}
					} else {
						this.$emit("update:date", e);
					}
				} else if (e) {
					// Если передано только одно значение и оно не пустое, то считаем, что это первая дата
					this.$emit("update:date", [e, null]);
				} else {
					// Если передано пустое значение, сбрасываем выбор
					this.$emit("update:date", []);
				}
			} else {
				this.$emit("update:date", e);
			}
			this.$emit("change");
		},
		// * Преобразование ISO в dd.mm.yyyy
		formatDate() {
			if (!this.date) return "";
			const [year, month, day] = this.date.split("-");
			return `${day}.${month}.${year}`;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__control:not(.in-table) {
	align-items: center !important;
	min-height: 32px !important;
	font-size: 13px !important;
}
::v-deep .v-input__append-inner:not(.in-table) {
	align-items: center !important;
	align-self: stretch !important;
}
::v-deep .v-input__icon .v-icon:not(.in-table) {
	font-size: 19px !important;
}

::v-deep .v-text-field.in-table {
	&:focus-within {
		border-color: #77dc99 !important;
		// box-shadow: 0px 0px 4px #81e9a3;
	}
}
::v-deep .v-text-field.in-table > .v-input__control {
	min-height: 27px !important;
	font-size: 14px !important;
}

::v-deep .v-text-field__details {
	display: none !important;
}
.width-small {
	width: 130px;
}

</style>
