<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<v-card-title
				class="d-flex justify-space-between border-bottom pt-3 pb-2"
			>
				<span class="subtitle-1">
					<h3 class="font-weight-medium black--text">Добавление жителя</h3>
				</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon color="dark">mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-form ref="form" lazy-validation>
						<!-- Alert -->
						<v-row v-if="alert">
							<v-col cols="12">
								<v-alert dense text :type="alert.color">
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
						<!-- Персональные данные -->
						<v-row>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Фамилия</label>
								<v-text-field
									placeholder="Фамилия"
									v-model="data.surname"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Имя</label>
								<v-text-field
									placeholder="Имя"
									v-model="data.name"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Отчество</label>
								<v-text-field
									placeholder="Отчество"
									v-model="data.patronymic"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Телефон
									<span class="red--text">*</span>
								</label>
								<v-text-field
									v-model="data.phone"
									:placeholder="phoneCode+'-'"
									v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									:rules="rules.phone"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4">
								<label class="caption-2 mt-0">Дом
									<span class="red--text">*</span>
								</label>
								<Autocomplete
									v-model="data.house_id"
									clearable
									required
									placeholder="Выберите дом"
									item-text="apartment_address"
									item-value="id"
									:filter="addressFilter"
									:items="houses"
									dense
								></Autocomplete>
							</v-col>
							<v-col cols="12" md="4">
								<label class="caption-2 mt-0">Тип помещения
									<span class="red--text">*</span>
								</label>
								<Autocomplete
									v-model="data.type_id"
									clearable
									required
									placeholder="Выберите тип"
									item-text="name"
									item-value="sys_name"
									:items="catalog.room_types"
									dense
								></Autocomplete>
							</v-col>
						</v-row>
						<v-row v-if="data.type_id == 'apartment'">
							<v-col md="4" cols="12" >
								<label class="caption-2 mt-0">Номер квартиры
									<span class="red--text">*</span>
								</label>
								<v-text-field
									placeholder="Номер квартиры"
									v-model="apartment.apartment_number"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
                  required
                  :rules="rules.required"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Подъезд
									<span class="red--text">*</span>
								</label>
								<v-select
									v-if="porchList.length"
									v-model="apartment.porch_number"
									class="border-all"
									solo
									dense
									hide-details
									flat
									item-text="name"
									item-value="name"
									:items="porchList"
									required
									:rules="rules.required"
								></v-select>
								<TextField
									v-else
									v-model="apartment.porch_number"
									required
									:rules="rules.required"
								></TextField>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Этаж
									<span class="red--text">*</span>
								</label>
								<v-text-field
									placeholder="Этаж"
									v-model="apartment.floor_number"
									class="border-all"
									solo
									dense
									flat
									required
									hide-details="auto"
									:rules="rules.required"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Тип прописки
									<span class="red--text">*</span>
								</label>
                <v-select
                  placeholder="Выберите"
									v-model="data.affilation_id"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									item-text="name"
									item-value="id"
									:items="catalog.apartments_affiliations"
									:rules="rules.required">

                </v-select>
							</v-col>
						</v-row>
						<v-row v-else-if="data.type_id == 'non-residential-premises'">
							<v-col md="4" cols="12" >
								<label class="caption-2 mt-0">Номер НП
									<span class="red--text">*</span>
								</label>
								<v-text-field
									placeholder="Номер НП"
									v-model="nonResidential.apartment_number"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
                  required
                  :rules="rules.required"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Вид деятельность
									<span class="red--text">*</span></label>
								<TextField
									v-model="nonResidential.activity"
									required
									:rules="rules.required"
								></TextField>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Название НП
									<span class="red--text">*</span>
								</label>
								<TextField
									v-model="nonResidential._name"
									required
									:rules="rules.required"
								></TextField>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Тип прописки
									<span class="red--text">*</span>
								</label>
                <v-select
                  placeholder="Выберите"
									v-model="data.affilation_id"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									item-text="name"
									item-value="id"
									:items="catalog.apartments_affiliations"
									:rules="rules.required">

                </v-select>
							</v-col>
						</v-row>
						<v-row v-else-if="data.type_id == 'parking-space'">
							<v-col md="4" cols="12" >
								<label class="caption-2 mt-0">Номер парковочного места
									<span class="red--text">*</span>
								</label>
								<v-text-field
									placeholder="Номер парковочного места"
									v-model="parking.apartment_number"
									class="border-all"
									solo
									dense
									flat
									hide-details="auto"
                  required
                  :rules="rules.required"
								>
								</v-text-field>
							</v-col>
							<v-col md="4" cols="12">
								<label class="caption-2 mt-0">Тип прописки
									<span class="red--text">*</span>
								</label>
                <v-select
                  placeholder="Выберите"
									v-model="data.affilation_id"
									class="border-all"
									solo
									dense
									flat
									hide-details
									required
									item-text="name"
									item-value="id"
									:items="catalog.apartments_affiliations"
									:rules="rules.required">

                </v-select>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize px-9"
					color="primary"
					depressed
					:loading="isSaving"
					@click="SaveTenant"
					:disabled="!disableButton()"
				>
					Добавить
				</v-btn>
			</v-card-actions>
		</v-card>

		<!-- Модальное окно -->
		<ModalWarning
			v-if="showModalWarning"
			:title="'Добавление нового сотрудника'"
			:text="'Вы успешно добавили нового сотрудника'"
			@close-modal="closeModal"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalWarning from "@/components/app/ModalWarning";

import addressFilterMixin from "@/mixins/addressFilterMixin";

export default {
	name: "EmployeeTableModal",
	mixins: [addressFilterMixin],
	components: {
		ModalWarning,
	},
	props: {
		dataToEdit: {
			type: Object,
			default: null,
		},
    catalog: {
      type: Object,
      default: null,
    },
	},
	data() {
		return {
			alert: null,
			data: {
				name: '',
				surname: '',
				patronymic: '',
				phone: null,
				house_id: null,
				type_id: null,
				affilation_id: null,
				source: "2",
				change_registration: true
			},
			apartment: {
				porch_number: null,
				floor_number: null,
				apartment_number: null,
			},
			nonResidential: {
				apartment_number: '',
				activity: '',
				_name: '',
			},
			parking: {
				apartment_number: '',
			},
			porchList: [],
			date: new Date().toISOString().substr(0, 10),
			dialog: true,
			edited: false,
			loading: true,
			showModalWarning: false,
			isSaving: false,
			rules: {
				required: [v => !!v || ""],
				phone: [
					v => !!v || "",
					v => this.phoneCode === '+7' ? ((v && v.length === 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
				],
				houses: [v => !v.length],
			},
		};
	},
	watch: {
		'data.house_id'() {
			if(this.data.house_id) {
				let params = {
					house_id: this.data.house_id
				}
				this.$api.dropdowns.load_porch_list(params)
				.then(res => {
					this.porchList = res;
				})
			}
		}
	},
	computed: {
		...mapGetters({
			houses: "ksk/GET_HOUSES",
			phoneCode: "auth/GET_USER_PHONE_CODE",
			tokenDevice: "notifications/GET_DEVICE_TOKEN",
		}),
		cRequestCategories() {
			return this.catalog.request_categories;
		},
		cRoles() {
			const roles = ["Менеджер кск", "Работник кск", "Диспетчер ук"];
			return this.catalog.roles
				.filter(e => roles.includes(e.name))
				.map(e => {
					const name = e.name.includes("Работник кск")
						? "Сотрудник"
						: e.name.split(" ")[0];
					return { ...e, name };
				});
		},
	},
	methods: {
		disableButton() {
			if(this.data.phone && this.data.house_id && this.data.type_id) {
				return this.data.phone.length > 14 ? true : false;
			} else {
				return false;
			}
		},
		closeModal(isConfirmed = true) {
			this.dialog = false;
			this.$emit("close-modal", isConfirmed);
		},
    async SaveTenant() {
			this.alert = null;
			if (!this.$refs.form.validate()) {
				return;
			}
			try {
				this.data.firebase_token = this.tokenDevice
				this.isSaving = true;
				let data;
				const selectedType = this.catalog.room_types.find(type => type.sys_name === this.data.type_id);
				if(this.data.type_id == 'apartment') {
					data = {
					...this.data,
					...this.apartment
					}
					data.type_id = selectedType.id;
				} else if(this.data.type_id == 'non-residential-premises') {
					data = {
						...this.data,
						...this.nonResidential
					}
					data.type_id = selectedType.id;
				} else if(this.data.type_id == 'parking-space') {
					data = {
						...this.data,
						...this.parking
					}
					data.type_id = selectedType.id;
				}

				const res = await this.$api.ksk.add_tenant_bpmn({
					data: data,
				});
				this.alert = {
					color: "success",
					message: "Изменения сохранены",
				};

				setTimeout(() => this.$emit("close-modal", true), 500);
				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Попробуйте еще раз",
				};
			} finally {
				this.isSaving = false;
			}
		},
		// async saveEmployee() {
		// 	this.alert = null;

		// 	if (!this.$refs.form.validate()) {
		// 		return;
		// 	}

		// 	// Choose at least one address
		// 	if (!this.data.houses_ids.length) {
		// 		this.alert = {
		// 			color: "error",
		// 			message: "Выберите как минимум 1 обслуживаемый адрес",
		// 		};
		// 		return;
		// 	}

		// 	try {
		// 		this.isSaving = true;

		// 		if (this.dataToEdit) {
		// 			const data = { ...this.data };
		// 			delete data.is_manager;

		// 			await this.$api.ksk.save_employee(data, this.dataToEdit._id);
		// 			this.alert = {
		// 				color: "success",
		// 				message: "Изменения сохранены",
		// 			};
		// 			// setTimeout(() => this.$emit("close-modal"), 500);
		// 		} else {
		// 			await this.$api.ksk.add_employee(this.data);
		// 			this.showModalWarning = true;
		// 		}
		// 		this.$emit("update-info");
		// 	} catch (error) {
		// 		this.alert = {
		// 			color: "error",
		// 			message: error.message || "Произошла ошибка. Попробуйте еще раз",
		// 		};
		// 	} finally {
		// 		this.isSaving = false;
		// 	}
		// },
	},

	async created() {
		if (this.dataToEdit) {
			this.data = this.dataToEdit.deepCopy();
			this.data.employee_position_id = this.data?.employee_position._id || null;
			delete this.data.employee_position;
			delete this.data._id;
		}
	},
	async mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .col {
	padding-bottom: 0 !important;
}
.required::after {
	content: "*";
	position: absolute;
	color: red;
	margin-left: 3px;
}
</style>
